<template>
  <Page fixed full>
    <iframe src="https://learn.bolsterbuilt.com" class="w-full h-full"></iframe>
  </Page>
</template>

<script>
import PageMixin from '@/components/mixins/Page'

export default {
  mixins: [PageMixin],
  name: 'Academy Page'
}
</script>
